import React from "react";
import { ReactSVG } from "react-svg";
import { ITag } from "../../../types/cores/tag";
import { converToMinutes } from "../../../utils/convertTime";
import { ButtonComponent } from "../button/button";
import { ColorPickerComponent } from "../color-picker/color-picker";
import { DropdownComponent } from "../../cores/dropdown/dropdown";
import style from "./tag-item.module.scss";
import { AccountContext } from "../../../context/account";
import { getAPI } from "../../../utils/getApi";
import {
  downloadVideoClipAPI
} from "../../../api/tags";

interface ITagItemComponent {
  tag: ITag;
  onClick: (tag: ITag) => void;
  onEdit: (tag: ITag) => void;
  onDelete: (id: string) => void;
}

export const TagItemComponent = ({
  tag,
  onClick,
  onDelete,
  onEdit,
}: ITagItemComponent) => {
  const [isDelete, setIsDelete] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [color, setColor] = React.useState<string>(tag.color);
  const [comment, setComment] = React.useState<string>(tag.comment);
  const [hide, setHide] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { getAccount } = React.useContext(AccountContext);
  const [videoClips, setVideoClips] = React.useState<any>();
  const [afterSet, setAfterSet] = React.useState(false);
  const getClips = async (session: any) => {
        const data = await getAPI("session/video-clips", session, "", "", "", [{
          key: "sessionId",
          value: tag.sessionId,
        },{
            key: "videoTagId",
            value: tag.id,
        }]);
        var durationList = [];
        for (var i = 0; i < data.videoClips.length; i++) {
            durationList.push(data.videoClips[i].duration);
        }
        setVideoClips(durationList);
        setAfterSet(true);
    };

  function sendClipDownloadRequest(clipDuration: Number) {
  setLoading(true);
  getAccount().then(async (session: any) => {
    downloadVideoClipAPI(session, [
        {
            key: "sessionId",
            value: tag.sessionId,
        },
        {
            key: "videoTagId",
            value: tag.id,
        },
        {
            key: "clipDurationSeconds",
            value: parseInt(clipDuration.toString()),
        },
    ])
    .then((response) => {
        if(response.code === "ERR_BAD_RESPONSE") {
            return setError(true);
        }
        else {
            getClips(session);
            return setLoading(false);
        }
    })
  });
  }
  const onEditOpen = (tag: ITag) => {
    setColor(tag.color);
    setComment(tag.comment);
    setIsEdit(true);
  };
  React.useEffect(() => {
      getAccount().then((session: any) => {
        getClips(session);
      });
    }, []);
  return (
    <div className={` ${style["tag-item"]} widget-container`}>
      {!isEdit ? (
        <div style={{ minHeight: "35px" }}>
          <div className={style["tag-item-title"]}>
            <small>
              <b>{converToMinutes(tag.tagTime * 1000)}</b>
            </small>
            <span onClick={() => tag.tagTime > 0 && onClick(tag)}>
              <span
                className={style["tag-item-color"]}
                style={{ backgroundColor: tag.color }}
              />
              {tag.comment}
            </span>
          </div>
          <span className={style["tag-item-icons"]}>
          {loading === false && afterSet ?
           <div hidden={hide}>
            <DropdownComponent title={"Clip Duration"}>
                        <ul className={style["header-profile"]}>
                        <li onClick={() => sendClipDownloadRequest(30)}>
                            {videoClips.includes(30) ? <ReactSVG title="This clip was already made, so download will be faster." style={{display: "inline-block", }}src="/icons/check.svg"/> : ""}30 sek.
                        </li>
                        <li onClick={() => sendClipDownloadRequest(60)}>
                            {videoClips.includes(60) ? <ReactSVG title="This clip was already made, so download will be faster." style={{display: "inline-block", }}src="/icons/check.svg"/> : ""}60 sek.
                        </li>
                        <li onClick={() => sendClipDownloadRequest(90)}>
                            {videoClips.includes(90) ? <ReactSVG title="This clip was already made, so download will be faster." style={{display: "inline-block", }}src="/icons/check.svg"/> : ""}90 sek.
                        </li>
                        <li onClick={() => sendClipDownloadRequest(120)}>
                            {videoClips.includes(120) ? <ReactSVG title="This clip was already made, so download will be faster." style={{display: "inline-block", }}src="/icons/check.svg"/> : ""}120 sek.
                        </li>
                        </ul>
                      </DropdownComponent> </div> : error === false ? <div> <p> -Exporting clip, please wait- </p> </div> : <div> <p> An error occured while exporting clip.</p> </div>
            }

            <div>
            <ReactSVG title="Download Clip" src="/icons/import.svg" onClick={() => hide ? setHide(false) : setHide(true)}/>
            </div>

            {/* clipDuration > 0 ? getAccount().then(async (session: any) => {
                            downloadVideoClipAPI(session, [
                            {
                            key: "sessionId",
                            value: tag.sessionId,
                            },
                            {
                            key: "videoTagId",
                            value: tag.id,
                            },
                            {
                            key: "clipDurationSeconds",
                            value: parseInt(clipDuration.toString()),
                            },]);}) */}
            <ReactSVG src="/icons/edit.svg" onClick={() => onEditOpen(tag)} />
            <ReactSVG
              src="/icons/delete.svg"
              onClick={() => setIsDelete(true)}
            />
          </span>
        </div>
      ) : (
        <div style={{ minHeight: "70px" }}>
          <small>
            <b>{converToMinutes(tag.tagTime * 1000)}</b>
          </small>
          <ColorPickerComponent color={color} onSelect={setColor} />
          <textarea
            rows={4}
            value={comment}
            onChange={(e: any) => setComment(e.target.value)}
          />
          <div className={style["tag-item-buttons"]}>
            <ButtonComponent
              title="Save"
              variant="secondary"
              onClick={() => {
                onEdit({ ...tag, color: color, comment: comment });
                setIsEdit(false);
              }}
            />
            <ButtonComponent
              title="Cancel"
              variant="secondary"
              onClick={() => setIsEdit(false)}
            />
          </div>
        </div>
      )}
      {isDelete && (
        <div className={style["tag-item-delete"]}>
          <div>
            <ButtonComponent
              title="Delete"
              variant="secondary"
              onClick={() => {
                onDelete(tag.id);
                setIsDelete(false);
              }}
            />
            <ButtonComponent
              title="Cancel"
              variant="secondary"
              onClick={() => setIsDelete(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

import axios from "axios";
import { getURL } from "../utils/getFilterUrl";
const API_URL = process.env.REACT_APP_API_URL;

export const addVideoTagsAPI = async (
  { accessToken }: any,
  profilesID: string[],
  filters: any[]
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.jwtToken}`,
    },
  };
  return await axios
    .post(`${API_URL}session/video-tag?` + getURL(filters), profilesID, config)
    .then((response: any) => response.data)
    .catch(console.log);
};

export const editVideoTagsAPI = async (
  { accessToken }: any,
  profilesID: string[],
  filters: any[]
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.jwtToken}`,
    },
  };
  return await axios
    .put(`${API_URL}session/video-tag?` + getURL(filters), profilesID, config)
    .then((response: any) => response.data)
    .catch(console.log);
};
export const deleteVideoTagAPI = async (
  { accessToken }: any,
  filters: any[]
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.jwtToken}`,
    },
  };
  return await axios
    .delete(`${API_URL}session/video-tag?` + getURL(filters), config)
    .then((response: any) => response.data)
    .catch(console.log);
};
export const downloadVideoClipAPI = async (
  { accessToken }: any,
  filters: any[]
) => {
  // trying to use config for the get request later, while having the responseType set as anything, throws this error:
  //  Types of property 'responseType' are incompatible.
  //      Type 'string' is not assignable to type 'ResponseType | undefined'.
  // responseType needs to be set for the blob to not utf-8 encode whatever it receives.
  // calling the get request directly on the contents of config, without using the const config, works fine, but it doesnt work using the const config specifically for responseType.
  // >:(
  /*
    const config = {
        responseType: 'arraybuffer',
        headers: {
            Authorization: `Bearer ${accessToken.jwtToken}`,
        },
    };
  console.log({
    responseType: "arraybuffer",
    headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
  });
  */
  return await axios
    // {responseType... is what usually would be set through the const config.
    .get(`${API_URL}session/download-video-clip?` + getURL(filters), {
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    })
    .then((response) => {
      //console.log(response.data);
      const responseBlob = new Blob([response.data]);
      const url = window.URL.createObjectURL(responseBlob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      let startFileNameIndex =
        response?.headers?.["content-disposition"].indexOf('"') + 1;
      let endFileNameIndex =
        response?.headers?.["content-disposition"].lastIndexOf('"');
      a.download = response?.headers?.["content-disposition"]
        .substring(startFileNameIndex, endFileNameIndex)
        .replaceAll(":", "_")
        .replaceAll(" ", "_");

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return response;
    })
    .catch(function (e) {
      //handle error
      console.log("Something went wrong!: ");
      //console.log(e);
      return e;
    })
    .catch(console.log);
};
